import getFolders from "@/db/folder/getFolders";
import getPrograms from "@/db/program/getPrograms";
import useAdmin from "@/hooks/useAdmin";
import useFolder from "@/hooks/useFolder";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useOwner from "@/hooks/useOwner";
import { MAX_WIDTH } from "@/lib/constants";
import { Folder } from "@/models/folder";
import { Program } from "@/models/program";
import { useQuery } from "react-query";
import styled from "styled-components";
import Items from "../Library/Items";
import FolderBar from "./FolderBar";
import FolderTabBar from "./FolderTabBar";

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;
`;

type Props = {};

const FolderComponent: React.FC<Props> = () => {
  const owner = useOwner();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const folder = useFolder();

  const foldersQuery = useQuery<Folder[]>(
    ["folders", owner?.id, folder?.id, admin],
    getFolders(owner, admin, folder?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
  const folders = foldersQuery.data || [];

  const programsQuery = useQuery<Program[]>(
    ["programs", owner?.id, admin, folder?.id],
    getPrograms(owner, admin, folder?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
  const programs = programsQuery.data || [];

  return (
    <Wrapper>
      {admin && <FolderTabBar />}
      {!admin && groupAdmin && <FolderBar />}
      <Items folders={folders} programs={programs} />
    </Wrapper>
  );
};

export default FolderComponent;
