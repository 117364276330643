import styled from "styled-components";
import Image from "next/image";
import { colors } from "@/lib/styles";
import CopyLink from "@/components/universal/CopyLink";
import { ownerDomain } from "@/lib/owner";
import useOwner from "@/hooks/useOwner";
import Status from "@/components/universal/Status";
import useAdmin from "@/hooks/useAdmin";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useGroup from "@/hooks/useGroup";
import useFolder from "@/hooks/useFolder";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Logo = styled.div`
  position: relative;
  height: 45px;
  width: 45px;
  margin-right: 15px;
`;

const Title = styled.h1`
  font-size: 21px;
  font-weight: 600;
  color: ${colors.nearBlack};
  margin: 0;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

type Props = {};

const FolderBar: React.FC<Props> = (props) => {
  const owner = useOwner();
  const admin = useAdmin();
  const folder = useFolder();
  const group = useGroup();
  const groupAdmin = useGroupAdmin();

  return (
    <Wrapper>
      <Left>
        {folder?.image && (
          <Logo>
            <Image src={folder.image} objectFit="contain" layout="fill" />
          </Logo>
        )}
        <Title>{folder?.title}</Title>
        {admin && <Status folder={folder} style={{ marginLeft: 15 }} />}
      </Left>
      <Right>
        {admin ? (
          <CopyLink link={`${ownerDomain(owner)}/folder/${folder?.id}`} />
        ) : groupAdmin ? (
          <CopyLink
            link={`${ownerDomain(owner)}/folder/${folder?.id}?group=${
              group?.id
            }`}
          />
        ) : null}
      </Right>
    </Wrapper>
  );
};

export default FolderBar;
