import TabBar from "@/components/universal/TabBar";
import { ownerPath } from "@/lib/path";
import styled from "styled-components";
import useOwner from "@/hooks/useOwner";
import CopyLink from "../universal/CopyLink";
import { ownerDomain } from "@/lib/owner";
import useAdmin from "@/hooks/useAdmin";
import useGroupAdmin from "@/hooks/useGroupAdmin";
import useGroup from "@/hooks/useGroup";
import Status from "../universal/Status";
import useFolder from "@/hooks/useFolder";

const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
`;

export const FolderTabBar: React.FC = (props) => {
  const owner = useOwner();
  const folder = useFolder();
  const admin = useAdmin();
  const groupAdmin = useGroupAdmin();
  const group = useGroup();

  return (
    <>
      <TabBar
        title={folder?.title}
        image={folder?.image}
        action={
          admin ? (
            <Action>
              <Status folder={folder} style={{ marginRight: "auto" }} />
              <CopyLink link={`${ownerDomain(owner)}/folder/${folder?.id}`} />
            </Action>
          ) : groupAdmin ? (
            <Action>
              <CopyLink
                style={{ marginLeft: "auto" }}
                link={`${ownerDomain(owner)}/folder/${folder?.id}?group=${
                  group?.id
                }`}
              />
            </Action>
          ) : null
        }
        tabs={
          admin
            ? [
                {
                  title: "Content",
                  name: "content",
                  path: ownerPath(
                    owner,
                    folder ? [...folder?.slugPaths, folder?.slug].join("/") : ""
                  ),
                },
                {
                  title: "Settings",
                  name: "settings",
                  path: ownerPath(owner, `folder/${folder?.id}/settings`),
                },
              ]
            : []
        }
      />
    </>
  );
};

export default FolderTabBar;
